import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import type { SerializeFrom } from '@remix-run/node';
import type { MoneyAmount } from '@medusajs/medusa/dist/models/money-amount';
import type { MetaDescriptor } from '@remix-run/react';
import type { Seo } from '@cms/types';

export function isBrowser() {
  return typeof window !== 'undefined';
}

export function formatPriceFromNumber(amount: number | undefined | null) {
  if (amount == null) {
    return '';
  }

  return new Intl.NumberFormat('nl-NL', {
    currency: 'eur',
    minimumFractionDigits: 2,
  }).format(amount);
}

export function formatPrice(
  money: SerializeFrom<MoneyAmount> | MoneyAmount | undefined | null,
) {
  if (!money) {
    return '';
  }

  return new Intl.NumberFormat('nl-NL', {
    currency: 'eur',
    minimumFractionDigits: 2,
  }).format((money.amount || 0) / 100);
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function sortByField<T>(
  items: T[],
  selector: (item: T) => any,
  asc: boolean = true,
) {
  return items.sort((a, b) => {
    const aValue = selector(a);
    const bValue = selector(b);
    return compareFunction(aValue, bValue, asc);
  });
}

export function compareFunction(
  aValue: any,
  bValue: any,
  asc: boolean,
): number {
  if (typeof aValue === 'boolean' || typeof aValue === 'number') {
    return compareFunctionNumberOrBoolean(aValue, bValue, asc);
  }
  return compareFunctionString(aValue, bValue, asc);
}

export function compareFunctionNumberOrBoolean(
  aValue: any,
  bValue: any,
  asc: boolean,
): number {
  return asc ? aValue - bValue : bValue - aValue;
}

export function compareFunctionString(
  aValue: any,
  bValue: any,
  asc: boolean,
): number {
  const returnValue = asc ? -1 : 1;
  if (aValue < bValue) {
    return 0 + returnValue;
  }

  if (aValue > bValue) {
    return 0 - returnValue;
  }

  return 0;
}

export function seoToMeta(seo: SerializeFrom<Seo> | Seo | null | undefined) {
  const meta: MetaDescriptor[] = [];
  if (!seo) {
    return meta;
  }

  if (seo.metaTitle) {
    meta.push({ title: seo.metaTitle });
  }

  if (seo.metaDescription) {
    meta.push({
      name: 'description',
      content: seo.metaDescription,
    });
  }

  return meta;
}

export function getPixelPath() {
  const folder = process.env.CLOUDINARY_FOLDER;
  return `${folder}/pixel`;
}
